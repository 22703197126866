$list-grid-template: 250px 250px .8fr .8fr .5fr 160px;

.contents {
    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-btn {
        &-aproval {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid rgba(#3161f1, 0.1);
            padding: 8px 18px;
            height: auto;
            font-weight: 400;

            svg {
                margin-right: 15px;
            }

            .ant-badge {
                margin-left: 8px;

                &-count {
                    background: #e9e9e9;
                }
            }

            .ant-btn-icon {
                margin-right: 15px;
                height: 18px;
                width: 18px;
            }
        }
    }

    .ant-page-header {
        padding: 6px 0px;
        padding-top: 0;

        &-heading-title {
            font-weight: 700;
        }
    }

    &-types {
        .ant-btn {
            padding: 10px 40px;
            height: auto;
        }
    }

    &-filter {
        margin-top: 15px;
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;

        &-left {
            display: flex;
            gap: 10px;
        }

        &-right {
            display: flex;
            gap: 15px;
            align-items: center;
        }
    }

    &-list {
        margin-top: 20px;

        &-header {
            display: grid;
            grid-template-columns: $list-grid-template;
            gap: 20px;
            align-items: flex-start;
            padding-left: 30px;
            margin-bottom: 20px;

            &-item {
                font-size: 15px;

                &-status {
                    padding-left: 25px;
                }
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &-body-item {
            display: grid;
            grid-template-columns: $list-grid-template;
            align-items: center;
            text-align: center;
            background: #ffffff;
            border-radius: 5px;
            gap: 20px;
            height: 70px;
            padding-left: 18px;
            padding-right: 18px;
        }

        &-name {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        &-action {
            display: flex;
            gap: 10px;
            justify-content: center;

            .ant-btn {
                padding: 0;
            }
        }

        &-item-serial {
            padding-left: 5px;
        }

        &-item-title-span {
            padding-left: 2px;
        }

        &-item-avatar {
            margin-right: 5px;
        }

        &-item-title {
            color: black;
        }

        &-item-username {
            padding-left: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &-item-title:hover {
            color: #3161f1;
        }

        &-item-username:hover {
            cursor: pointer;
            color: #3161f1;
        }

        &-item-enrollment {
            text-align: start;
            padding-left: 10px;
        }

        &-item-publish-date {
            text-align: start;
            padding-left: 10px;
            white-space: nowrap;
        }

        &-status {
            padding-left: 10px;
        }
    }
}

.ant-pagination-options {
    visibility: hidden;
}