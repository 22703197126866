.tb-profileNav {
    position: relative;
    display: flex;

    &-profile {
        display: flex;
        gap: 12px;
        align-items: center;

        &-name {
            font-weight: 500;
            font-size: 13px;
            letter-spacing: 0.01em;
            color: #334d6e;
        }
    }
}

.tb-profileNav:hover {
    cursor: pointer;
}

.tb-signout {
    margin-top: 12px;
    position: absolute;
    top: 140%;
    right: -7px;
    width: 180px;
    z-index: 11;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 7px;

    &-button {
        cursor: pointer;
        background-color: transparent;
        width: calc(100% - 14px);
        padding: 8px;
        border-radius: 5px;
        font-family: var(--theme-f-family-primary);
        font-size: 14px;
        font-weight: 500;
        border: none;
        text-align: left;
        padding: 15px;
        padding-left: 30px;
        display: flex;
        align-items: center;
        margin: 6px;
        color: #959595;


        svg {
            margin-right: 20px;
        }

        &:hover {
            color: #FE5555;
            background-color: #F5F5F5;

            svg path {
                fill: #FE5555;
                ;
            }
        }
    }

    &-upArrow {
        top: -8px;
        position: absolute;
        right: 14px;
    }
}