.my-profile {
    &-header {
        &-text {
            font-weight: bold;
            padding-bottom: 20px;
        }
    }

    &-profile-picture {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        &-label {
            font-weight: 700;
            margin-right: 50px;
        }

        &-btn {
            &-change {
                color: rgba(49, 97, 241, 1);
                font-weight: 400;
            }

            &-remove {
                color: rgba(255, 92, 92, 1);
                font-weight: 400;
            }
        }
    }

    &-name {
        display: flex;
        align-items: center;

        // padding-bottom: 20px;
        &-label {
            min-width: 65px;
            margin-right: 50px;
        }

        &-input {
            min-width: 200px;
            max-width: 400px;
        }
    }

    &-email {
        display: flex;
        align-items: center;

        // padding-bottom: 20px;
        &-label {
            min-width: 65px;
            margin-right: 50px;
        }

        &-input {
            min-width: 400px;
            max-width: 400px;
        }
    }

    &-username {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        &-label {
            min-width: 65px;
            margin-right: 50px;
        }

        &-input {
            min-width: 400px;
            max-width: 400px;
            margin-right: 10px;
        }

        &-info {
            color: rgb(159, 158, 177);
            font-size: small;
        }
    }

    &-security-label {
        font-weight: bold;
    }

    &-password {
        display: flex;
        align-items: center;
        padding-bottom: 70px;

        &-label {
            margin-right: 25px;
        }

        &-btn {
            &-generate {
                background: linear-gradient(180deg, #788BA4 0%, #4B607C 100%);
                font-weight: normal;
                margin-left: -25px;
            }
        }

        &-input {
            min-width: 400px;
            max-width: 400px;
            margin-left: -25px;
        }
    }

    &-update {
        &-btn-primary {
            margin-top: 70px;
            font-weight: normal;
            height: 45px;
            border-radius: 3px;
        }
    }
}

.ant-input {
    border-radius: 5px;
    border: 0px;
}

.ant-input[disabled] {
    background-color: rgba(217, 221, 227, 1);
}

.ant-form-item-label>label::after {
    content: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.ant-form-item-label {
    text-align: left;
    min-width: 65px;
    margin-right: 50px;
}

.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
}