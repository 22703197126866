$list-grid-template: 250px 220px .7fr .7fr .6fr 100px;

.users {
    .ant-page-header {
        padding: 6px 0px;
        padding-top: 0;

        &-heading-title {
            font-weight: 700;
        }
    }

    &-header {
        &-count {
            padding-top: 15px;
            padding-bottom: 10px;

            &-span {
                font-weight: 700;
                font-size: 16px;
            }
        }
    }

    &-types {
        &-btn {
            padding: 10px 40px;
            height: auto;
        }
    }

    &-list {
        margin-top: 20px;

        &-header {
            display: grid;
            grid-template-columns: $list-grid-template;
            // gap: 20px;
            padding-left: 30px;
            padding-right: 18px;
            align-items: flex-start;
            margin-bottom: 20px;
        }

        &-body {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &-item {
                display: grid;
                grid-template-columns: $list-grid-template;
                background: #ffffff;
                height: 70px;
                border-radius: 5px;
                padding-left: 18px;
                padding-right: 18px;
                align-items: center;
                text-align: center;

                &-btn {
                    padding: 5px 20px;
                    border-radius: 20px;
                    height: auto;
                }

                &-avatar {
                    margin-right: 10px;
                }

                &-email {
                    text-align: start;
                    padding-left: 10px;
                }

                &-enrollment {
                    text-align: start;
                    padding-left: 50px;
                }

                &-unenrollment {
                    text-align: start;
                    padding-left: 50px;
                }

                &-check {
                    &-button {
                        float: left;
                    }
                }

                &-action {
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                }

                &-username {
                    text-align: left;

                    &-link {
                        color: black;
                    }

                    &-link:hover {
                        cursor: pointer;
                        color: #3161f1;
                    }
                }
            }
        }

        &-select-action {
            width: 120px;
        }
    }

}

.ant-pagination-options {
    visibility: hidden;
}