$trashed-tips-list-grid-template: 300px 250px .8fr .8fr 160px;

.trashed-tips {
    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-filter {
        display: flex;
        gap: 10px;
    }

    &-filter-search {
        margin-top: 15px;
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;

        &-left {
            display: flex;
            gap: 10px;
        }

        &-right {
            display: flex;
            gap: 15px;
            align-items: center;
        }
    }

    .ant-page-header {
        padding: 6px 0;
        padding-top: 0px;

        &-heading-title {
            font-weight: 900;
        }
    }

    &-types {
        &-btn {
            padding: 10px 40px;
            height: auto;
        }
    }

    &-apply-btn {
        padding: 5px 25px;
        height: auto;
        margin-bottom: 7px;
        color: #ffffff;
        background-color: #000000;
        background-image: linear-gradient(#828282, #4E5055);
    }

    &-list {
        margin-top: 20px;

        &-header {
            display: grid;
            grid-template-columns: $trashed-tips-list-grid-template;
            // gap: 20px;
            align-items: flex-start;
            padding-left: 30px;
            margin-bottom: 20px;

            &-item {
                font-size: 15px;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;

            &-item {
                display: grid;
                grid-template-columns: $trashed-tips-list-grid-template;
                align-items: center;
                background-color: #ffffff;
                height: 70px;
                border-radius: 5px;
                padding-left: 10px;
                padding-right: 25px;
                // box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.1);
                margin-bottom: 7px;
            }
        }

        &-item {
            &-title {
                &-checkbox {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            &-author {
                padding-left: 20px;
                color: black;

                &-avatar {
                    margin-right: 5px;
                }
            }

            &-publish-date {
                padding-left: 20px;
            }

            &-deleted-by {
                padding-left: 20px;
            }

            &-action {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }
}

.action-btn {
    padding: 0px 10px;
}

.select-option-width {
    width: 182px;
}

.search-box-width {
    width: 230px;
}