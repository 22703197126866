$user-created-tips-list-grid-template: 300px 1fr 1fr 0.7fr 160px;

.user-created-tips {
    padding-bottom: 20px;

    &-list {
        font-weight: normal;

        &-header {
            display: grid;
            grid-template-columns: $user-created-tips-list-grid-template;
            // gap: 20px;
            align-items: flex-start;
            padding-left: 30px;
            padding-right: 18px;
            padding-top: 5px;
            padding-bottom: 15px;

            &-item {
                font-size: 15px;
            }

            &-item-action {
                padding-left: 15px;
            }
        }

        &-item {
            display: flex;

            &-title {
                color: black;

                &-serial {
                    padding-left: 15px;
                }
            }

            &-title:hover {
                color: #3161f1;
            }
        }

        &-body-items {
            display: grid;
            grid-template-columns: $user-created-tips-list-grid-template;
            align-items: center;
            background: #ffffff;
            border-radius: 5px;
            // gap: 20px;
            height: 70px;
            padding-left: 10px;
            padding-right: 25px;
            box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.1);
            margin-bottom: 7px;
        }

        &-body {
            padding-left: 10px;
            padding-right: 25px;
            margin-bottom: 7px;

            &-item {
                display: flex;

                &-title {
                    display: flex;
                    color: black;

                    &-serial {
                        padding-left: 5px;
                    }

                    &-span {
                        padding-left: 5px;
                        text-align: start;
                    }
                }

                &-title:hover {
                    color: #3161f1;
                }

                &-category {
                    text-align: start;
                    padding-left: 10px;
                }

                &-publish-date {
                    padding-left: 10px;
                }

                &-action {
                    &-button {
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}

.ant-pagination-options {
    visibility: hidden;
}

.ant-input {
    border: 1px solid #000000;
}