.ant-layout {
    min-height: 100vh;
}
.ant-layout-content {
    margin: 20px 40px !important;
}
.sidebar {
    box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
    flex: 0 0 255px !important;
    max-width: 255px !important;
    min-width: 255px !important;
    width: 255px !important;
    left: 0;
    top: 0;
    bottom: 0;
    .brand {
        padding: 20px;
    }
    .ant-menu-item-group {
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.01em;
        color: #334d6e;
    }
    .ant-menu-item .ant-menu-item-icon + span,
    .ant-menu-submenu-title .ant-menu-item-icon + span,
    .ant-menu-item .anticon + span,
    .ant-menu-submenu-title .anticon + span {
        margin-left: 14px;
    }
    .ant-menu-item {
        padding-left: 34px !important;
        font-family: var(--font-family-secondary);
    }
    .ant-menu-item-group-title {
        padding-left: 34px !important;
        font-weight: bold;
        font-size: 12px;
        color: #2d3748;
        text-transform: uppercase;
    }
}

.site-header {
    background: #ffffff;
    display: flex;
    width: 100%;
    min-height: 67px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
}
