.user {
    &-span {
        padding-left: 4px;
        ;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #3161F1;
    }

    &-span:hover {
        color: #3161F1;
    }

    &-tips {
        &-list {
            margin-top: 20px;
            padding-left: 20px;
        }

        &-tabs {
            font-weight: bold;
        }
    }

    &-info {
        margin-top: 21px;

        &-column {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &-grid {
            padding: 24px;

            &-email-address {
                font-size: small;
                display: flex;
            }

            &-muted {
                color: #C2CFE0;
            }

            &-bold {
                font-weight: bold;
            }

            &-social-logo {
                display: flex;
                margin-top: 10px;

                &-item {
                    margin-right: 5px;
                }

                &-item:hover {
                    cursor: pointer;
                }
            }

            &-user-name {
                font-size: medium;
                font-weight: bold;
            }

            &-svg {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        &-divider {
            border: 1px solid rgba(248, 248, 248, 1);
            height: 252px;
            margin-top: 0;
        }

        &-action {
            display: flex;
            padding-top: 5px;
        }

        &-action:hover {
            cursor: pointer;
        }
    }

    &-avatar {
        // margin-bottom: 16px;
    }
}

.ant-card-body {
    padding: 0px;
}