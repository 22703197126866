$enrolled-tips-list-grid-template: 300px 200px 250px 1fr .5fr;

.users-enrolled-tips {
    padding-bottom: 20px;

    &-list {
        font-weight: normal;

        &-header {
            display: grid;
            grid-template-columns: $enrolled-tips-list-grid-template;
            padding-left: 30px;
            padding-right: 18px;
            // gap: 20px;
            align-items: flex-start;
            padding-top: 5px;
            padding-bottom: 15px;

            &-item {
                font-size: 15px;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 25px;

            &-item {
                display: grid;
                grid-template-columns: $enrolled-tips-list-grid-template;
                align-items: center;
                height: 70px;
                border-radius: 5px;
                box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.1);
                margin-bottom: 7px;

                &-title {
                    display: flex;

                    &-serial {
                        padding-left: 15px;
                    }

                    &-span {
                        display: flex;
                        padding-left: 5px;
                        text-align: start;
                        color: black;
                    }

                    &-span:hover {
                        color: #3161f1;
                    }
                }

                &-category {
                    text-align: start;
                    padding-left: 20px;
                }

                &-author {
                    padding-left: 20px;
                    color: black;

                    &-avatar {
                        margin-right: 5px;
                    }
                }

                &-enrollment-date {
                    padding-left: 20px;
                }

                &-action {
                    padding-left: 20px;
                }

                &-author:hover {
                    cursor: pointer;
                    color: #3161f1;
                }
            }
        }
    }
}

.ant-pagination-options {
    visibility: hidden;
}