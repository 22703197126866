// select
.ant-select-selector {
    height: auto !important;
    padding: 2.5px 18px !important;
}
.ant-select-selection-item {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: rgba(#030229, 0.7) !important;
}
