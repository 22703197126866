.user-info-overview {
    padding-bottom: 20px;
    &-categories {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        font-weight: normal;
        &-item {
            &-svg {
                margin-right: 4px;
            }
        }
    }
    &-categories>* {
        flex: 0 1 14.28%;
        justify-content: space-between;
    }
    &-statistics {
        margin-top: 25px;
        display: flex;
        &-item {
            width: 66%
        }
    }
}